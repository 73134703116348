import React, { useState } from "react";

import AppContext from "./AppContext";

import moment from "moment";

// 設計Provider
const AppContextProvider = (props) => {
  const [updateTime, setUpdateTime] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss.sss")
  );

  return (
    //這Provider提供 name 跟 setName 給子組件用(子組件可用 useContext獲取)
    <AppContext.Provider
      value={{
        updateTime,
        setUpdateTime,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
