import React, { useContext, useState, useEffect } from "react";
import { Input, Typography, message, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import ReactDataSheet from "react-datasheet";
import * as _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "react-datasheet/lib/react-datasheet.css";
import "./CalcMaterial.scss";

const { TextArea } = Input;

const { Title, Text } = Typography;

export default function CalcMaterial() {
  const [datasheetGrid, setDatasheetGrid] = useState([]); //excel 資料 grid

  const [copyStr, setCopyStr] = useState(undefined); //複製的文字

  const onChange = (e) => {
    let dataList = [];
    let resultList = [];
    let resultStrForExcelCopy = "";

    //每一行 用換行分解
    const eachRowDataList = _.split(e.target.value, "\n");

    if (_.size(eachRowDataList)) {
      // for each 每一行
      _.forEach(eachRowDataList, (item) => {
        // 用 tab \t 分解
        const rowSplitArr = _.split(item, "\t");
        //['1', 'CIR2000001', 'CIR-201 H夾具 (雙孔)陽極15u+壓克力10u', '個', '1892', '1900', '']

        //如果是項次開頭或是數量為空白則pass
        if (rowSplitArr[0] === "項次" || rowSplitArr[4] === "") {
        } else {
          //加到 datalist
          const obj = {
            product_id: rowSplitArr[1],
            product_desc: rowSplitArr[2],
            product_unit: rowSplitArr[3],
            product_count: rowSplitArr[4],
            product_pre_count: rowSplitArr[5],
            product_remark: rowSplitArr[6],
          };

          dataList.push(obj);
        }
      });

      // 找 product_id distinct 值
      const productIdList = _.sortBy(
        _.uniqBy(dataList, "product_id"),
        "product_id"
      );

      //for each 每一個id
      _.forEach(productIdList, (pidItem) => {
        //找相同產品編號

        const filterRes = _.filter(
          dataList,
          (i) => i.product_id === pidItem.product_id
        );

        //總數量
        const totalCount = _.sumBy(filterRes, (o) =>
          _.toNumber(o.product_count)
        );

        //總預備料量
        const totalPreCount = _.sumBy(filterRes, (o) =>
          _.toNumber(o.product_pre_count)
        );

        //備註
        let remarkList = [];

        _.forEach(filterRes, (item) => {
          if (_.size(item.product_remark)) {
            remarkList.push(item.product_remark);
          }
        });

        const obj = {
          product_id: pidItem.product_id,
          product_desc: filterRes[0].product_desc,
          product_unit: filterRes[0].product_unit,
          product_count: totalCount,
          product_pre_count: totalPreCount,
          product_remark: _.uniq(remarkList).toString(),
        };

        resultList.push(obj);
      });

      //轉成 data sheet value

      let sheetGrid = [];

      _.forEach(resultList, (item) => {
        const list = [
          { value: item.product_id },
          { value: item.product_desc },
          { value: item.product_unit },
          { value: item.product_count },
          { value: item.product_pre_count },
          { value: item.product_remark },
        ];

        sheetGrid.push(list);

        // const arr = [
        //   { value: item.product_id },
        //   { value: item.product_desc },
        //   { value: item.product_unit },
        //   { value: item.product_count },
        //   { value: item.product_pre_count },
        // ];
        // sheetGrid.push(arr);
      });

      //更新 sheet grid
      setDatasheetGrid(sheetGrid);
    }
  };

  /**
   *
   *
   * 複製table
   *
   */
  const copyTable = () => {
    const elTable = document.querySelector("table");

    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();

    message.success("複製成功!");
  };

  return (
    <div className="calc-material-area">
      <Title>料單計算</Title>
      <Text type="secondary">
        ✨ 複製 [ 項次 產品編號 品名規格 單位 數量 預備料合計 備註 ]
        欄位後貼在下方 <br />✨ 產品編號 數量 需"非空白"
      </Text>

      {/* Text Area */}
      <TextArea rows={10} allowClear onChange={onChange} />
      <Text type="secondary"></Text>

      {/* Result */}
      <div className="result-title-area">
        <div className="result-title">
          <Title>Result</Title>
        </div>

        {/*  Clipboard*/}

        {_.size(datasheetGrid) > 0 && (
          <Button
            type="primary"
            icon={<CopyOutlined />}
            shape="round"
            onClick={() => copyTable()}
          >
            {" "}
            copy to clipboard
          </Button>
        )}
      </div>

      {/* data sheet */}
      <div className="result-data-sheet-area">
        {_.size(datasheetGrid) > 0 && (
          <ReactDataSheet
            data={datasheetGrid}
            valueRenderer={(cell) => cell.value}
          />
        )}
      </div>
    </div>
  );
}
